import * as React from "react";
import Layout from "../components/layout/layout";
import { SeoEN, NoIndex } from "../components/seo";
import "../assets/css/index.css";
import { translations } from "../locales/legalNotice.js";

const LegalNoticePage = () => {
  return (
    <Layout page="legal" language="en">
      <main className="page-container container">
        <div className="page">
          <h1 className="page-title">{translations.en.title}</h1>
          <p>{translations.en.paragraph1}</p>
          <p>{translations.en.paragraph2}</p>
          <p>{translations.en.paragraph3}</p>
          <p>{translations.en.paragraph4}</p>
          <p>Email: hi@kolibird.app</p>
          <p>{translations.en.paragraph5}</p>
          <p className="bold">{translations.en.paragraph6}</p>
          <p>{translations.en.paragraph7}</p>
          <p className="bold">{translations.en.paragraph8}</p>
          <p>{translations.en.paragraph9}</p>
          <p>{translations.en.paragraph10}</p>
          <p>{translations.en.paragraph11}</p>
          <p className="bold">{translations.en.paragraph12}</p>
          <p>{translations.en.paragraph13}</p>
          <p className="bold">{translations.en.paragraph14}</p>
          <p>{translations.en.paragraph15}</p>
          <p>{translations.en.paragraph16}</p>
          <ol>
            <li>{translations.en.paragraph17}</li>
            <li>{translations.en.paragraph18}</li>
            <li>{translations.en.paragraph19}</li>
            <li>{translations.en.paragraph20}</li>
            <li>{translations.en.paragraph21}</li>
            <li>{translations.en.paragraph22}</li>
          </ol>
          <p className="bold">{translations.en.paragraph23}</p>
          <p>{translations.en.paragraph24}</p>
          <p>{translations.en.paragraph25}</p>
          <p>{translations.en.paragraph26}</p>
          <p className="bold">{translations.en.paragraph27}</p>
          <p>{translations.en.paragraph28}</p>
          <p className="bold">{translations.en.paragraph29}</p>
          <p>{translations.en.paragraph30}</p>
          <p>{translations.en.paragraph31}</p>
          <p>{translations.en.paragraph32}</p>
          <p className="bold">{translations.en.paragraph33}</p>
          <p>{translations.en.paragraph34}</p>
        </div>
      </main>
    </Layout>
  );
};

export default LegalNoticePage;

export const Head = () => (
  <>
    <SeoEN />
    <NoIndex />
  </>
);
